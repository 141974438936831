// **** Preset example **** //
// div data-controller="fixed-area" data-fixed-area-target="container"
//  .is-fixed
//  #tab-content-${currentTabId} .is-fixed
//    div data-action="click->fixed-area#tabClickHandler
//    label(tab panel).active(default tab)
//  .is-fixed
//  div(tab content)
//    .is-fixed.is-fixed-in-tab-content
//    .is-fixed.is-fixed-in-tab-content fixed-scroll-area-container w-full overflow-hidden z-60 md:relative md:bg-transparent
//      .fixed-scroll-area z-60 w-full md:max-w-187.5 absolute
//  .main-scroll-area overflow-x-scroll (id if tabs exist)
//  .fixed-area-bottom

// Update for fixed area in PC screen
// Add class names 'fixed-scroll-area-container' for fixed area container
//  Add class names 'md:max-w-187.5 absolute' for fixed area

// general
// <div class="is-fixed fixed-scroll-area-container overflow-hidden z-60 bg-blueGray-50 w-full md:relative md:bg-transparent">
//  <%= render Blocks::FollowUpComponent.new(category: :consultant, link_path: car_consultant_step1_path) %>
//  <div class='fixed-scroll-area is-fixed-hidden-el-area z-60 w-full md:max-w-187.5 absolute'>
//    <%= render Estimate::TableComponent.new(items: @item_list) do |t| %>
//      <%= render partial: "fixed_area", locals: { t: t, f: f } %>
//    <% end %>
//  </div>
// </div>

// Include tab element
// <div class="is-fixed-in-tab-content fixed-scroll-area-container z-60 bg-blueGray-50 w-full overflow-hidden">
//   <%= render Blocks::FollowUpComponent.new %>
//
//   <div class='fixed-scroll-area is-fixed-hidden-el-area z-60 w-full md:max-w-187.5 absolute'>
//     <%= render Estimate::TableComponent.new(items: items) do |t| %>
//       <%= render partial: "fixed_area_clone_item", locals: { t: t, f: f, plan_code: plan_code } %>
//     <% end %>
//   </div>
// </div>

// First row have to add 'origin-fixed-scroll-area'(!important)
// tr origin-fixed-scroll-area

const fixedElementTopList = {}
const currentPositionInfo = {
  bodyHeight: 0,
  absoluteElementsTop: {},
}

const recordCurrentBodyHeight = () => {
  const bodyHeight = document
    .querySelector('body')
    .getBoundingClientRect().height
  currentPositionInfo.bodyHeight = bodyHeight
}

const findCurrentFixedTabContentElements = (container, defaultId = null) => {
  const currentTab = container.querySelector('.tab__item.active')

  if (currentTab) {
    const currentTabId =
      defaultId === null
        ? currentTab.id.match(/^tab-label-(.+)$/)[1]
        : defaultId
    const currentTabContent = container.querySelector(
      `#tab-content-${currentTabId}`
    )
    const elementsInCurrentTabContent = currentTabContent.querySelectorAll(
      '.is-fixed-in-tab-content'
    )
    const fixedScrollAreaElement =
      currentTabContent.querySelector('.fixed-scroll-area')
    const mainScrollAreaElement =
      currentTabContent.querySelector('.main-scroll-area')

    return {
      elementsInCurrentTabContent,
      currentTabContent,
      fixedScrollAreaElement,
      mainScrollAreaElement,
    }
  }

  const mainScrollAreaElement = container.querySelector('.main-scroll-area')
  const fixedScrollAreaElement = container.querySelector('.fixed-scroll-area')

  return {
    elementsInCurrentTabContent: null,
    currentTabContent: container,
    mainScrollAreaElement,
    fixedScrollAreaElement,
  }
}

const findFixedElements = (containerTarget, elementsInCurrentTabContent) => {
  const fixedElements = Array.from(
    containerTarget.querySelectorAll('.is-fixed')
  )
  if (elementsInCurrentTabContent !== null) {
    elementsInCurrentTabContent.forEach((content) =>
      fixedElements.push(content)
    )
  }

  return fixedElements
}

const updateFixedElementTopList = (
  startFixedPoint,
  containerTarget = document
) => {
  const { elementsInCurrentTabContent } =
    findCurrentFixedTabContentElements(containerTarget)
  const fixedElements = findFixedElements(
    containerTarget,
    elementsInCurrentTabContent
  )

  fixedElements.forEach((el, index) => {
    const elementHeight = el.getBoundingClientRect().height

    if (index === 0) {
      fixedElementTopList[index] = startFixedPoint
      fixedElementTopList[index + 1] = startFixedPoint + elementHeight
    } else {
      fixedElementTopList[index + 1] =
        fixedElementTopList[index] + elementHeight
    }
  })
}

const addFixedStylesToElementsInMobile = (
  startFixedPoint,
  containerTarget = document,
  defaultId = null
) => {
  const { elementsInCurrentTabContent, fixedScrollAreaElement } =
    findCurrentFixedTabContentElements(containerTarget, defaultId)
  const fixedElements = findFixedElements(
    containerTarget,
    elementsInCurrentTabContent
  )
  const headerHeigh =
    document.querySelector('header').getBoundingClientRect().height || 0
  const topMenuHeigh =
    document
      .querySelector('[class^="header__sp-nav-menu-wrapper"]')
      .getBoundingClientRect().height || 0
  const isNotWorkTiming =
    window.scrollY > currentPositionInfo.absoluteElementsTop[0] ||
    window.scrollY <= headerHeigh + topMenuHeigh

  if (isNotWorkTiming) {
    return
  }

  fixedScrollAreaElement.classList.remove('top-0')
  fixedElements.forEach((el, index) => {
    const element = el
    const elementHeight = element.getBoundingClientRect().height
    element.classList.add('fixed')

    // remove absolute when click tab
    if (element.classList.contains('absolute')) {
      element.classList.remove('absolute')
    }

    if (index === 0) {
      element.style.top = `${startFixedPoint}px`
      fixedElementTopList[index] = startFixedPoint
      fixedElementTopList[index + 1] = startFixedPoint + elementHeight
    } else {
      element.style.top = `${fixedElementTopList[index]}px`
      fixedElementTopList[index + 1] =
        fixedElementTopList[index] + elementHeight
    }
  })
}

const putFixedElementsToTableBottomInMobile = (
  isDownScroll,
  containerTarget = document
) => {
  const {
    elementsInCurrentTabContent,
    currentTabContent,
    mainScrollAreaElement,
    fixedScrollAreaElement,
  } = findCurrentFixedTabContentElements(containerTarget)
  const fixedElements = findFixedElements(
    containerTarget,
    elementsInCurrentTabContent
  )
  const bottomElement = currentTabContent.querySelector('.fixed-area-bottom')
  const fixedAreaHeight = fixedScrollAreaElement.getBoundingClientRect().height
  const lastElementHeight =
    fixedElements[fixedElements.length - 1].getBoundingClientRect().bottom +
    fixedAreaHeight
  const fixedContainerHeight = fixedElementTopList[fixedElements.length] - 0
  const mainAreaHight = mainScrollAreaElement.getBoundingClientRect().height
  const headerHeigh =
    document.querySelector('header').getBoundingClientRect().height || 0
  const topMenuHeigh =
    document
      .querySelector('[class^="header__sp-nav-menu-wrapper"]')
      .getBoundingClientRect().height || 0
  const firstFixedPosition =
    mainAreaHight +
    headerHeigh +
    topMenuHeigh -
    fixedContainerHeight -
    fixedAreaHeight
  let absolutePositionCount = 0
  const shouldRemoveAbsolute =
    currentPositionInfo.absoluteElementsTop[0] >= window.scrollY

  fixedElements.forEach((el, index) => {
    absolutePositionCount =
      absolutePositionCount === 0 ? firstFixedPosition : absolutePositionCount
    const fixedElement = el
    const isAbsolute = fixedElement.classList.contains('absolute')
    const isOverBottomElement =
      lastElementHeight >= bottomElement.getBoundingClientRect().top

    if (isDownScroll && isOverBottomElement && isAbsolute === false) {
      fixedElement.classList.remove('fixed')
      fixedElement.classList.add('absolute')
      fixedElement.style.top = `${absolutePositionCount}px`
      currentPositionInfo.absoluteElementsTop[index] = absolutePositionCount
      absolutePositionCount += fixedElement.getBoundingClientRect().height
    }

    if (shouldRemoveAbsolute && isAbsolute) {
      fixedElement.classList.remove('absolute')
      fixedElement.classList.add('fixed')
      fixedElement.style.top = `${fixedElementTopList[index]}px`
      absolutePositionCount = 0
    }
  })
}

const putFixedElementsToTableBottomInPC = (containerTarget, isDownScroll) => {
  const { mainScrollAreaElement, fixedScrollAreaElement, currentTabContent } =
    findCurrentFixedTabContentElements(containerTarget)
  const originFixedScrollArea = currentTabContent.querySelector(
    '.origin-fixed-scroll-area'
  )
  const { top } = originFixedScrollArea.getBoundingClientRect()
  const fixedAreaContainer = currentTabContent.querySelector(
    '.fixed-scroll-area-container'
  )
  const mainScrollAreaBottom =
    mainScrollAreaElement.getBoundingClientRect().bottom
  const mainScrollAreaHeight =
    mainScrollAreaElement.getBoundingClientRect().height
  const mainScrollAreaTop = mainScrollAreaElement.getBoundingClientRect().top
  const fixedScrollAreaHeight =
    fixedScrollAreaElement.getBoundingClientRect().height
  const isAbsolute = fixedScrollAreaElement.classList.contains('absolute')
  const fixedBottomPoint =
    window.scrollY +
    mainScrollAreaTop +
    mainScrollAreaHeight -
    fixedScrollAreaHeight
  const isBottom = top + fixedScrollAreaHeight <= 0
  const isFixedTiming =
    mainScrollAreaBottom <= fixedScrollAreaHeight && isDownScroll && !isAbsolute
  const isRemoveFixedTiming =
    isBottom &&
    mainScrollAreaBottom >= fixedScrollAreaHeight &&
    !isDownScroll &&
    isAbsolute

  if (isFixedTiming) {
    fixedAreaContainer.classList.remove('md:relative')
    fixedScrollAreaElement.classList.remove('fixed')
    fixedScrollAreaElement.classList.add('absolute')
    fixedScrollAreaElement.style.top = `${fixedBottomPoint}px`
  }

  if (isRemoveFixedTiming) {
    fixedAreaContainer.classList.add('md:relative')
    fixedScrollAreaElement.classList.remove('absolute')
    fixedScrollAreaElement.classList.add('fixed')
    fixedScrollAreaElement.removeAttribute('style')
  }
}

const addPaddingTopForMainArea = (
  containerTarget = document,
  defaultId = null
) => {
  const { elementsInCurrentTabContent, mainScrollAreaElement } =
    findCurrentFixedTabContentElements(containerTarget, defaultId)
  const fixedElements = findFixedElements(
    containerTarget,
    elementsInCurrentTabContent
  )

  if (mainScrollAreaElement.style.paddingTop === '') {
    // one scrollBarHeight is 17px; there are two scrollbars because scrollbar-x-rotate twice
    const scrollBarHeight = Number(34)
    const totalPadding =
      fixedElements.reduce(
        (acc, currentEl) =>
          acc + Number(currentEl.getBoundingClientRect().height),
        0
      ) - scrollBarHeight

    mainScrollAreaElement.style.paddingTop = `${totalPadding}px`
  }
}

const displayFixedScrollAreaElement = (containerTarget = document) => {
  const { fixedScrollAreaElement } =
    findCurrentFixedTabContentElements(containerTarget)
  fixedScrollAreaElement.classList.remove('hidden')
}

const removeMobileFixedStyles = (elements, containerTarget) => {
  if (elements.length === 0) {
    return
  }

  const { fixedScrollAreaElement, mainScrollAreaElement, currentTabContent } =
    findCurrentFixedTabContentElements(containerTarget)
  const fixedAreaContainer = currentTabContent.querySelector(
    '.fixed-scroll-area-container'
  )
  const originFixedScrollArea = currentTabContent.querySelector(
    '.origin-fixed-scroll-area'
  )
  const { top } = originFixedScrollArea.getBoundingClientRect()
  const isStartPoint = top >= 0

  // remove fixed style form sp
  fixedAreaContainer.classList.remove('fixed')
  elements.forEach((element) => {
    element.removeAttribute('style')
    element.classList.remove('fixed')
  })

  mainScrollAreaElement.removeAttribute('style')

  // fixed fixed scroll area if the scroll position is not to start point
  if (isStartPoint) {
    fixedScrollAreaElement.classList.remove('top-0')
  } else {
    fixedScrollAreaElement.classList.remove('absolute')
    fixedScrollAreaElement.classList.add('fixed')
    fixedScrollAreaElement.classList.add('top-0')
  }

  fixedScrollAreaElement.classList.remove('fixed')
  fixedScrollAreaElement.classList.add('absolute')
}

const fixedElementHandlerInPC = (containerTarget, isDownScroll) => {
  const { fixedScrollAreaElement, mainScrollAreaElement, currentTabContent } =
    findCurrentFixedTabContentElements(containerTarget)
  const originFixedScrollArea = currentTabContent.querySelector(
    '.origin-fixed-scroll-area'
  )
  const { top } = originFixedScrollArea.getBoundingClientRect()
  const mainScrollAreaHeight =
    mainScrollAreaElement.getBoundingClientRect().height
  const mainScrollAreaTop = mainScrollAreaElement.getBoundingClientRect().top
  const fixedScrollAreaHeight =
    fixedScrollAreaElement.getBoundingClientRect().height
  const fixedBottomPoint =
    window.scrollY +
    mainScrollAreaTop +
    mainScrollAreaHeight -
    fixedScrollAreaHeight
  const isAbsolute = fixedScrollAreaElement.classList.contains('absolute')
  const isFixedTiming =
    window.scrollY <= fixedBottomPoint && top <= 0 && isDownScroll && isAbsolute
  const isRemoveFixedTiming = top >= 0 && !isDownScroll && !isAbsolute

  if (isFixedTiming) {
    fixedScrollAreaElement.classList.remove('absolute')
    fixedScrollAreaElement.classList.add('fixed')
    fixedScrollAreaElement.classList.add('top-0')
  }

  if (isRemoveFixedTiming) {
    fixedScrollAreaElement.classList.remove('fixed')
    fixedScrollAreaElement.classList.remove('top-0')
    fixedScrollAreaElement.classList.add('absolute')
  }
}

const calculateTableScrollAreaWidth = (containerTarget) => {
  const { fixedScrollAreaElement, mainScrollAreaElement } =
    findCurrentFixedTabContentElements(containerTarget)
  const mainScrollAreaWidth =
    mainScrollAreaElement.getBoundingClientRect().width

  fixedScrollAreaElement.style.width = `${mainScrollAreaWidth}px`
}

const calculateTableScrollAreaWidthForAllTab = (containerTarget) => {
  const { mainScrollAreaElement } =
    findCurrentFixedTabContentElements(containerTarget)
  const fixedScrollAreaElements =
    containerTarget.querySelectorAll('.fixed-scroll-area')
  const mainScrollAreaWidth =
    mainScrollAreaElement.getBoundingClientRect().width
  fixedScrollAreaElements.forEach((el) => {
    el.style.width = `${mainScrollAreaWidth}px`
  })
}

export {
  addFixedStylesToElementsInMobile,
  putFixedElementsToTableBottomInMobile,
  putFixedElementsToTableBottomInPC,
  recordCurrentBodyHeight,
  updateFixedElementTopList,
  addPaddingTopForMainArea,
  findCurrentFixedTabContentElements,
  displayFixedScrollAreaElement,
  removeMobileFixedStyles,
  fixedElementHandlerInPC,
  calculateTableScrollAreaWidth,
  calculateTableScrollAreaWidthForAllTab,
}
