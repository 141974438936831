import { Controller } from '@hotwired/stimulus'

function convertToHalfFont(chars) {
  /* eslint no-bitwise: ["error", { "allow": ["&"] }] */

  let result = ''
  chars = chars.replace(/[\uff0d\\-]/g, '')

  for (let i = 0, l = chars.length; i < l; i += 1) {
    let codepoint = chars[i].charCodeAt(0)
    if (codepoint >= 0xff00 && codepoint <= 0xffef) {
      codepoint = 0xff & (codepoint + 0x20)
    }
    result += String.fromCharCode(codepoint)
  }

  return result
}
export default class extends Controller {
  static targets = ['input']

  changeHandler() {
    const transformValue = convertToHalfFont(this.inputTarget.value)
    this.inputTarget.value = transformValue
  }
}
