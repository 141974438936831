import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  get fields() {
    return [...this.element.querySelectorAll('input, select')]
  }

  lock(e) {
    const rules = this.getTypeRules(e.params.rules, 'lock')

    for (const target of this.fields) {
      for (const rule of rules) {
        if (target.name !== rule.field) continue

        this.setTargetChecked(target, e, rule)
        target.toggleAttribute('disabled', e.target.value === rule.when)
      }
    }
  }

  reset(e) {
    const rules = this.getTypeRules(e.params.rules, 'reset')

    for (const target of this.fields) {
      for (const rule of rules) {
        if (target.name !== rule.field) continue
        if (e.target.value !== rule.when) continue

        if (Object.prototype.hasOwnProperty.call(rule, 'default')) {
          target.checked = target.value === rule.default
        }
      }
    }
  }

  getTypeRules(rules, type) {
    return (rules || []).filter((rule) => rule.type === type)
  }

  setTargetChecked(target, e, rule) {
    if (e.target.value === rule.when)
      target.checked = target.value === rule.when
  }
}
