import { Controller } from '@hotwired/stimulus'

// Add data attributes for target element.
// type is input
// div name="car_model"
//  input name: 'car_consultant_form[car_model]', data: {
//    controller: 'consultant-form-valid',
//    action: 'keyup->consultant-form-valid#validate',
//    valid: '["notBlank"]'
//  }
//  div class="input__alert"

// type is select
// div name="prefecture_use"
//  div class='pulldown-box'
//    select name: 'car_consultant_form[prefecture_use]',data: {
//      controller: 'consultant-form-valid',
//      action: 'blur->consultant-form-valid#validate',
//      valid: '["notChoice"]'
//  }
//  div class="input__alert"

// type is select date(multiple)
// div name="date_of_birth"
//   div data-controller="consultant-form-valid"
//    select data-action="blur->consultant-form-valid#validateDateSelect"
//    select
//    select
//   div class="input__alert"

const pHiragana = '\\u3041-\\u3096\\u309D-\\u309F|\\uD82C\\uDC01|\\uD83C\\uDE00'
const pKatakana =
  '\\u30A1-\\u30FA\\u30FD-\\u30FF\\u31F0-\\u31FF\\u32D0-\\u32FE\\u3300-\\u3357\\uFF66-\\uFF6F\\uFF71-\\uFF9D|\\uD82C\\uDC00'
const pHan =
  '\\u2E80-\\u2E99\\u2E9B-\\u2EF3\\u2F00-\\u2FD5\\u3005\\u3007\\u3021-\\u3029\\u3038-\\u303B\\u3400-\\u4DB5\\u4E00-\\u9FD5\\uF900-\\uFA6D\\uFA70-\\uFAD9|\\uD840-\\uD868\\uD86A-\\uD86C\\uD86F-\\uD872\\uDC00-\\uDFFF|\\uD869\\uDC00-\\uDED6\\uDF00-\\uDFFF|\\uD86D\\uDC00-\\uDF34\\uDF40-\\uDFFF|\\uD86E\\uDC00-\\uDC1D\\uDC20-\\uDFFF|\\uD873\\uDC00-\\uDEA1|\\uD87E\\uDC00-\\uDE1D'
const pChinese = '\\u4E00-\\u9FFF'
const japaneseNameRegex = `[^${pHiragana}|${pKatakana}|${pHan}|${pChinese}]+`

const validationMap = {
  notBlank: { regex: '^.{0}$', errorMsg: '入力してください' },
  onlyNumberAlphabet: {
    regex: '[^A-Za-z0-9-]+',
    errorMsg: '有効な文字を入力してください',
  },
  notChoice: { regex: '^.{0}$', errorMsg: '選択してください' },
  japaneseNameFormat: {
    regex: japaneseNameRegex,
    errorMsg: '有効な氏名を入力してください',
  },
  kanaNameFormat: {
    regex: `[^${pHiragana} \\s]`,
    errorMsg: '全角ひらがなで入力してください',
  },
  kanaFullNameFormat: {
    regex: `[^${pHiragana} \\s]`,
    errorMsg: '全角ひらがなで入力してください',
  },
  numberFromOneToHundred: {
    regex: '^(?!([1-9][0-9]?|100)$)[0-9]+$',
    errorMsg: '有効な文字を入力してください',
  },
  onlyNumber: { regex: '[^0-9]+', errorMsg: '有効な文字を入力してください' },
  numberOrFloat: { regex: '[^0-9.]+', errorMsg: '半角数字で入力してください' },
  phoneNumberCount: {
    regex: '^(.{1,9}|.{12,})$',
    errorMsg: '有効な電話番号を入力してください',
  },
  phoneNumberFirstWord: {
    regex: '^[^0].+$',
    errorMsg: '有効な電話番号を入力してください',
  },
  email: {
    regex: "^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$",
    errorMsg: '有効なメールアドレスを入力してください',
  },
  fullWidthCharacters: {
    regex: '[\\x00-\\xff]',
    errorMsg: '有効な文字を入力してください',
  },
  specificCharacters: {
    regex:
      "[^0-9a-zA-Z-()/.,'&:;０-９ａ-ｚＡ-Ｚー（）／．，’＆：；ぁ-んァ-ン一-龯]",
    errorMsg: '使用できない文字が入力されています',
  },
  postCodeLength: {
    regex: '^(.{1,6}|.{8,})$',
    errorMsg: '有効な郵便番号を入力してください',
  },
}

const createErrorMsgElement = (msg) => {
  const div = document.createElement('div')
  const p = document.createElement('p')
  div.classList = 'input__alert'
  p.classList = 'input__alert-msg'
  p.textContent = msg
  div.appendChild(p)

  return div
}

const isUnknownMileageForPastYearChecked = () => {
  const checkboxContainer = document.querySelector(
    '[name="is_unknown_mileage"]'
  )
  const checkbox = checkboxContainer.querySelector('[type="checkbox"')

  if (checkbox) {
    return checkbox.checked
  }

  return false
}

const findQuestionContainer = (target) => {
  const name = target.name.match(/^.*\[(.+)\]$/)[1]
  const parentElement = document.querySelector(`[name="${name}"]`)

  if (parentElement) {
    return parentElement
  }

  return target.parentNode
}

const checkAddressElements = () => {
  const addressElements = document.querySelectorAll('.js-valid-address')
  setTimeout(() => {
    addressElements.forEach((element) => {
      element.focus()
      element.blur()
    })
  }, 500)
}

const inputError = (msg, target) => {
  const container = findQuestionContainer(target)
  target.parentNode.classList.add('input-box--error')
  const alertMsgElement = container.querySelector('.input__alert-msg')

  if (alertMsgElement) {
    alertMsgElement.textContent = msg
  } else {
    const newAlertMsgElement = createErrorMsgElement(msg)
    container.appendChild(newAlertMsgElement)
  }
}

const selectError = (msg, target) => {
  const container = findQuestionContainer(target)
  target.parentNode.classList.add('input-box--error')
  const alertMsgElement = container.querySelector('.input__alert-msg')

  if (alertMsgElement) {
    alertMsgElement.textContent = msg
  } else {
    const modal = container.querySelector('[data-controller="modal"]')
    const newAlertMsgElement = createErrorMsgElement(msg)

    if (modal) {
      container.insertBefore(newAlertMsgElement, modal)
    } else {
      container.appendChild(newAlertMsgElement)
    }
  }
}

const removeError = (target) => {
  const container = findQuestionContainer(target)
  const errorMsgElement = container.querySelector('.input__alert')

  // remove error message element
  if (errorMsgElement) {
    errorMsgElement.remove()
  }

  const isSelectDate = target.classList.contains('pulldown-date--error') // special handler for select date
  const name = isSelectDate ? 'pulldown-date' : 'input-box'
  const errorContainer = container.querySelector(`.${name}--error`)

  // remove target border styles
  if (errorContainer) {
    errorContainer.classList.remove(`${name}--error`)
  }
}

const mapError = (msg, target) => {
  if (target.tagName === 'INPUT') {
    inputError(msg, target)
  }

  if (target.tagName === 'SELECT') {
    // special handler for mileage_for_past_year column
    const isMileageForPastYear =
      target.name.match(/^.*mileage_for_past_year.*$/) !== null
    if (isMileageForPastYear && isUnknownMileageForPastYearChecked()) {
      removeError(target)
      return
    }

    // special handler for select date
    const isSelectDate = target.classList.contains('pulldown-date__select')
    if (isSelectDate) {
      return
    }

    selectError(msg, target)
  }
}

const runValidations = (keys, target) =>
  keys.map((key) => {
    const regexRule = new RegExp(validationMap[key].regex)
    const isEmail = key === 'email'
    const matching = (target?.value || '').match(regexRule) !== null
    const invalid = (isEmail && !matching) || (!isEmail && matching)

    let passed = true

    // email regex is reverse, so get not matching
    if (invalid) {
      mapError(validationMap[key].errorMsg, target)
      passed = false
    }

    return passed
  })

const afterAllPass = (target) => {
  removeError(target)

  // special handler for postalCode
  const isPostalCode = target.classList.contains('p-postal-code')
  if (isPostalCode) {
    checkAddressElements()
  }
}

const afterAllPassForSelectDate = (target) => {
  const name = target.name.match(/.*\[(.+)?\(/)[1]
  const container = document.querySelector(`[name="${name}"]`)
  const errorElement = container.querySelector('.input__alert')

  if (errorElement) {
    errorElement.remove()
  }
}

const run = (target) => {
  const validKeys = JSON.parse(target.getAttribute('data-valid'))
  const result = runValidations(validKeys, target)
  const isAllPass = result.every((status) => status === true)

  if (isAllPass) {
    afterAllPass(target)
  }
}

export default class extends Controller {
  // single element
  validate(e) {
    this.target = e.target
    run(this.target)
  }

  // special handler for select date(multiple)
  validateDateSelect(e) {
    this.targetParent = e.target.parentNode
    const elements = Array.from(this.targetParent.children)
    elements.forEach((el) => run(el)) // checking single option is filled

    // checking all option is filled
    const result = elements.map((el) => {
      const validKeys = JSON.parse(el.getAttribute('data-valid'))

      return runValidations(validKeys, el)
    })

    const isAllPass = result.flat().every((status) => status === true)
    // remove error message element when all pass
    if (isAllPass) {
      afterAllPassForSelectDate(e.target)
    }
  }
}
